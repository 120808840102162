<template>
  <div class="app-container">
    <el-table
      border
      :data="datas"
      style="width: 100%"
      size="small"
      row-key="Key"
      :tree-props="{ children: 'Children' }"
      default-expand-all
      :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }"
    >
      <el-table-column prop="Code" label="编码" width="120"></el-table-column>
      <el-table-column prop="Name" label="名称" width="150"></el-table-column>
      <el-table-column
        prop="Manager"
        label="负责人"
        width="150"
      ></el-table-column>
      <el-table-column prop="Tel" label="电话" width="120"></el-table-column>
      <el-table-column prop="RegionName" label="城市" width="120">
      </el-table-column>
      <el-table-column
        prop="Address"
        label="地址"
        min-width="160"
      ></el-table-column>
      <el-table-column prop="IsOpen" label="开放预约" width="120">
        <template slot-scope="scope">
          <el-switch v-if="scope.row.Parent"
            v-model="scope.row.IsOpen"
            @change="handleOpen(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="AutoAudit" label="自动审核" width="120">
        <template slot-scope="scope">
          <el-switch v-if="scope.row.Parent"
            v-model="scope.row.AutoAudit"
            @change="handleAutoAudit(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="LimitAppoint" label="按计划预约" width="120">
        <template slot-scope="scope">
          <el-switch  v-if="scope.row.Parent"
            v-model="scope.row.LimitAppoint"
            @change="handleLimit(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="CheckTypes" label="开放项目" min-width="150">
        <template slot-scope="scope">
          <el-tag v-if="(scope.row.CheckTypes & 2) == 2">人体成分评估</el-tag>
          <el-tag v-if="(scope.row.CheckTypes & 4) == 4">足底健康评估</el-tag>
          <el-tag v-if="(scope.row.CheckTypes & 8) == 8">关节功能评估</el-tag>
          <el-tag v-if="(scope.row.CheckTypes & 16) == 16">三维动作捕捉</el-tag>
          <el-tag v-if="(scope.row.CheckTypes & 32) == 32">呼吸功能评估</el-tag>
          <el-tag v-if="(scope.row.CheckTypes & 64) == 64">心肺耐力评估</el-tag>
          <el-tag v-if="(scope.row.CheckTypes & 128) == 128">心电图运动负荷试验</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="Desc"
        label="备注"
        min-width="100"
      ></el-table-column>
      <el-table-column label="操作" width="160" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            v-if="!scope.row.Parent"
            @click="handleCreate(scope.row)"
            >添加门店</el-button
          >
          <el-button type="text" @click="handleUpdate(scope.row)"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.Parent"
            type="text"
            @click="handleRemove(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div v-if="formVisible">
      <el-dialog
        v-dragDialog
        :title="formTitle"
        :visible.sync="formVisible"
        width="600px"
        :close-on-click-modal="false"
      >
        <el-form
          :model="dataForm"
          ref="dataForm"
          label-width="150px"
          :rules="dataFormRules"
        >
          <el-form-item label="编码：" prop="Code">
            <el-input
              v-model="dataForm.Code"
              auto-complete="off"
              placeholder="请输入编码"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店名称：" prop="Name">
            <el-input
              v-model="dataForm.Name"
              auto-complete="off"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="负责人：" prop="Manager">
            <el-input
              v-model="dataForm.Manager"
              auto-complete="off"
              placeholder="请输入负责人名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" prop="Tel">
            <el-input
              v-model="dataForm.Tel"
              auto-complete="off"
              placeholder="请输入联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="所在城市：" prop="Region">
            <regionSelector2 ref="regionSelector" v-model="dataForm.Region" />
          </el-form-item>
          <el-form-item label="联系地址：" prop="Address">
            <el-input
              v-model="dataForm.Address"
              auto-complete="off"
              placeholder="请输入联系地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="开放项目：" prop="CheckTypes">
            <el-select
              style="width: 100%"
              size="small"
              v-model="dataForm.CheckTypes"
              multiple
              placeholder="请选择开放测试项目"
            >
              <el-option
                v-for="item in $config.checkType"
                :key="item.key"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：" prop="Desc">
            <el-input
              v-model="dataForm.Desc"
              type="textarea"
              auto-complete="off"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="formVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSubmit">提交</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import regionSelector2 from "@/components/regionSelector2";
import {
  getSingle,
  getTenantTree,
  switchLimit,
  save,
  del,
  switchOpen,
  switchAudit,
} from "@/api/setting/tenant.js";
export default {
  components: { regionSelector2 },
  data() {
    return {
      datas: [],
      formVisible: false,
      formTitle: "创建",
      dataForm: {},
      dataFormRules: {
        Name: [
          { required: true, message: "门店名称不能为空", trigger: "change" },
        ],
        Code: [{ required: true, message: "编码不能为空", trigger: "change" }],
        Manager: [
          { required: true, message: "负责人不能为空", trigger: "change" },
        ],
        Region: [
          { required: true, message: "请选择门店所在城市", trigger: "change" },
        ],
        Tel: [
          { required: true, message: "联系电话不能为空", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    queryAll() {
      let param = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      getTenantTree(param).then((res) => {
        this.datas = res;
      });
    },
    handleCreate(data) {
      this.formTitle = "新增";
      this.dataForm = {
        Parent: { Key: data.Key },
        CheckTypes: [],
      };
      this.formVisible = true;
    },
    handleUpdate(row) {
      this.formTitle = "修改";
      let param = { key: row.Key };
      let that = this;
      getSingle(param).then((res) => {
        that.dataForm = res;
        that.dataForm.Parent = { Key: row.Parent };
        that.dataForm.CheckTypes = that.checkTypesToArray(row.CheckTypes);
        that.formVisible = true;
      });
    },
    handleOpen(row) {
      let param = { key: row.Key, open: row.IsOpen };
      switchOpen(param)
        .then((res) => {
          if (res) {
            this.msg.successMsg("设置成功！");
          }
        })
        .catch((ex) => {
          row.IsOpen = !row.IsOpen;
          this.msg.errorMsg("设置失败！");
        });
    },
    handleLimit(row) {
      let param = { key: row.Key, open: row.LimitAppoint };
      switchLimit(param)
        .then((res) => {
          if (res) {
            this.msg.successMsg("设置成功！");
          }
        })
        .catch((ex) => {
          row.IsOpen = !row.IsOpen;
          this.msg.errorMsg("设置失败！");
        });
    },
    handleAutoAudit(row) {
      let param = { key: row.Key, open: row.AutoAudit };
      switchAudit(param)
        .then((res) => {
          if (res) {
            this.msg.successMsg("设置成功！");
          }
        })
        .catch((ex) => {
          row.IsOpen = !row.IsOpen;
          this.msg.errorMsg("设置失败！");
        });
    },
    handleSubmit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          let param = JSON.parse(JSON.stringify(this.dataForm));
          param.CheckTypes = this.arrayToValue(this.dataForm.CheckTypes);
          save(param).then((res) => {
            if (res) {
              this.formVisible = false;
              this.queryAll();
            }
          });
        }
      });
    },
    arrayToValue(vals) {
      let value = 0;
      if (vals && vals.length > 0) {
        vals.forEach((r) => {
          value = value | r;
        });
      }
      return value;
    },
    checkTypesToArray(val) {
      let arrays = [];
      this.$config.checkType.forEach((r) => {
        if ((val & r.value) == r.value) {
          arrays.push(r.value);
        }
      });
      return arrays;
    },
    handleRemove(row) {
      if (row.Children) {
        this.msg.errorMsg("当前数据有下级不可删除!!!");
      } else {
        this.$confirm("确定删除吗？", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let param = {
            key: row.Key,
          };
          del(param)
            .then((res) => {
              if (res) {
                this.queryAll();
              }
            })
            .catch((ex) => {});
        });
      }
    },
    handleSizeChange(val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.queryAll());
    },
    handleCurrentChange(val) {
      this.pageIndex = `${val}`;
      this.queryAll();
    },
  },
  mounted() {
    this.queryAll();
  },
};
</script>